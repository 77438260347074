<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>事故事件管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有事故事件" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="accidentManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增事故事件信息" icon="md-cloud-upload" v-if="false">
            <div class="form-wrapper">
              <Form
                ref="addAccident"
                :model="newItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="newItem.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="发生时间：" prop="happen_time">
                  <DatePicker
                    type="datetime"
                    placeholder=""
                    style="width: 200px"
                    v-model="newItem.happen_time"
                    format="yyyy-MM-dd HH:mm:ss"
                  ></DatePicker>
                </FormItem>
                <FormItem prop="continue_period" label="延误时长：">
                        <TimePicker
                          type="time"
                          placeholder="延误时长"
                          v-model="newItem.continue_period"
                        ></TimePicker>
                      </FormItem>
                <FormItem label="事故地点：" prop="position">
                  <Input v-model="newItem.position" placeholder="" />
                </FormItem>
                <FormItem label="事件简述：" prop="description">
                  <Input
                    v-model="newItem.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="事件原因：" prop="reason_summary">
                  <Input
                    v-model="newItem.reason_summary"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="归因分类：" prop="reason_interface">
                  <Select
                    v-model="newItem.reason_interface"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.reasonChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="具体归因分类：" prop="reason_point">
                  <Input
                    v-model="newItem.reason_point"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="五分钟晚点事件：" prop="five_minutes_delay_event">
                  <InputNumber
                    :max="100"
                    :min="0"
                    v-model="newItem.five_minutes_delay_event"
                  ></InputNumber>
                </FormItem>
                <FormItem label="五分钟晚点列次：" prop="five_minutes_delay_train">
                  <InputNumber
                    :max="100"
                    :min="0"
                    v-model="newItem.five_minutes_delay_train"
                  ></InputNumber>
                </FormItem>
                <FormItem label="责任单位：" prop="responsibility">
                  <Input
                    v-model="newItem.responsibility"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addAccident')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addAccident')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含事故事件相关数据的excel文件：
                  <a href="/public_files/templates/AccidentTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                iurl="/accident_alter"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>险性事件信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterAccident"
                :model="currentItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="currentItem.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="发生时间：" prop="happen_time">
                  <DatePicker
                    type="datetime"
                    placeholder=""
                    style="width: 200px"
                    v-model="currentItem.happen_time"
                    format="yyyy-MM-dd HH:mm:ss"
                  ></DatePicker>
                </FormItem>
                <FormItem prop="continue_period" label="延误时长：">
                        <TimePicker
                          type="time"
                          placeholder="延误时长"
                          v-model="currentItem.continue_period"
                        ></TimePicker>
                      </FormItem>
                <FormItem label="事故地点：" prop="position">
                  <Input v-model="currentItem.position" placeholder="" />
                </FormItem>
                <FormItem label="事件简述：" prop="description">
                  <Input
                    v-model="currentItem.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="事件原因：" prop="reason_summary">
                  <Input
                    v-model="currentItem.reason_summary"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="归因分类：" prop="reason_interface">
                  <Select
                    v-model="currentItem.reason_interface"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.reasonChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="具体归因分类：" prop="reason_point">
                  <Input
                    v-model="currentItem.reason_point"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="五分钟晚点事件：" prop="five_minutes_delay_event">
                  <InputNumber
                    :max="100"
                    :min="0"
                    v-model="currentItem.five_minutes_delay_event"
                  ></InputNumber>
                </FormItem>
                <FormItem label="五分钟晚点列次：" prop="five_minutes_delay_train">
                  <InputNumber
                    :max="100"
                    :min="0"
                    v-model="currentItem.five_minutes_delay_train"
                  ></InputNumber>
                </FormItem>
                <FormItem label="责任单位：" prop="responsibility">
                  <Input
                    v-model="currentItem.responsibility"
                    placeholder="Enter something..."
                  />
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterAccident')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function itemFac() {
  return {
    id:0,
    line_code:'',
    line_name:'',
    happen_time:null,
    reason_interface:'',
    reason_point:'',
    five_minutes_delay_event:0,
    five_minutes_delay_train:0,
    description:'',
    reason_summary:'',
    continue_period:null,
    position:'',
    responsibility:'',
  };
}

export default {
  name: "accidentManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      update_count: 0,
      columnsFormatData: [
        {
          title: "线路名称",
          width: 100,
          align: "center",
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_code;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.line_name
            );
          }
        },
        {
          title: "发生时间",
          key: "happen_time",
          width: 150,
          align: "center"
        },
        {
          title: "事件简述",
          key: "description",
          align: "center",
          tooltip: "true",
          minWidth: 150
        },
        {
          title: "归因分类",
          key: "reason_interface",
          width: 100,
          align: "center"
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              // h(
              //   "Button",
              //   {
              //     props: {
              //       size: "small",
              //     },
              //     style: { color: "green" },
              //     on: {
              //       click: () => {
              //         this.itemEdit(params.row);
              //       },
              //     },
              //   },
              //   "修改"
              // ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      newItem: itemFac(),
      currentItem:itemFac(),
      ruleValidate: {
        line_code: [
          {
            required: true,
            type: "string",
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        happen_time: [
          {
            required: true,
            type: "string",
            message: "请输入发生时间！",
            trigger: "blur",
          },
        ],
        continue_period: [
          {
            required: true,
            type: "time",
            message: "请输入持续时长！",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            type: "string",
            message: "请输入事件详情！",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            type: "string",
            message: "请输入发生地点！",
            trigger: "blur",
          },
        ],
        reason_summary: [
          {
            required: true,
            type: "string",
            message: "请输入事件原因！",
            trigger: "blur",
          },
        ],
        reason_interface: [
          {
            required: true,
            type: "string",
            message: "请选择事件原因分类！",
            trigger: "blur",
          },
        ],
        reason_point: [
          {
            required: true,
            type: "string",
            message: "请输入事件具体原因！",
            trigger: "blur",
          },
        ],
      },
      option:{
        reasonChoices: [
        {
          value: "客运",
          label: "客运",
        },
      ],
      },
      activeOption:{

      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getStaticOption();
    this.getActiveOption();
  },
  methods: {
    getStaticOption() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "accidentManage",
        })
        .then((response) => {
            this.option = response.data.res_record;
        });
    },
     getActiveOption() {
    this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "lineList",
        })
        .then((response) => {
          this.activeOption = {'lineChoices':response.data.res_record};
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addItem");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/accident_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("险性事件添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterItem");
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/accident_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("险性事件修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条险性事件信息：",
        content:
          "<p>" +
          "所属线路：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "险性事件简要：" +
          value.description +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/accident_alter", {
          userToken: userToken,
          askData: "delItem",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      this.currentItem = JSON.parse(JSON.stringify(value));
      this.scModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>